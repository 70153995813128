// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-danke-tsx": () => import("./../../../src/pages/danke.tsx" /* webpackChunkName: "component---src-pages-danke-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-h-40-tsx": () => import("./../../../src/pages/h40.tsx" /* webpackChunkName: "component---src-pages-h-40-tsx" */),
  "component---src-pages-helpnow-kontakt-tsx": () => import("./../../../src/pages/helpnow-kontakt.tsx" /* webpackChunkName: "component---src-pages-helpnow-kontakt-tsx" */),
  "component---src-pages-helpnow-tsx": () => import("./../../../src/pages/helpnow.tsx" /* webpackChunkName: "component---src-pages-helpnow-tsx" */),
  "component---src-pages-impactinvestment-tsx": () => import("./../../../src/pages/impactinvestment.tsx" /* webpackChunkName: "component---src-pages-impactinvestment-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-loesungen-tsx": () => import("./../../../src/pages/loesungen.tsx" /* webpackChunkName: "component---src-pages-loesungen-tsx" */),
  "component---src-pages-nachhaltigkeit-tsx": () => import("./../../../src/pages/nachhaltigkeit.tsx" /* webpackChunkName: "component---src-pages-nachhaltigkeit-tsx" */),
  "component---src-pages-subscriptionservice-tsx": () => import("./../../../src/pages/subscriptionservice.tsx" /* webpackChunkName: "component---src-pages-subscriptionservice-tsx" */),
  "component---src-pages-termin-buchen-tsx": () => import("./../../../src/pages/termin-buchen.tsx" /* webpackChunkName: "component---src-pages-termin-buchen-tsx" */),
  "component---src-pages-termin-checkout-tsx": () => import("./../../../src/pages/termin-checkout.tsx" /* webpackChunkName: "component---src-pages-termin-checkout-tsx" */),
  "component---src-pages-termin-investoren-tsx": () => import("./../../../src/pages/termin-investoren.tsx" /* webpackChunkName: "component---src-pages-termin-investoren-tsx" */),
  "component---src-pages-unternehmen-tsx": () => import("./../../../src/pages/unternehmen.tsx" /* webpackChunkName: "component---src-pages-unternehmen-tsx" */),
  "component---src-templates-blog-single-tsx": () => import("./../../../src/templates/blog-single.tsx" /* webpackChunkName: "component---src-templates-blog-single-tsx" */)
}

